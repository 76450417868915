* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: orangered;
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.App {
  padding: 5rem;
  background-color: black;
  border-radius: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 3rem;
  perspective: 800px;
  width: 1300px;
  height: 80vh;
  align-items: center;
  justify-content:  center;
}

.card {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 1s ease;
  transform: rotateY(180deg);
  transform-style: preserve-3d;
  border-radius: 1rem;
  cursor: pointer;
}

.front {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 10;
  border-radius: 1rem;
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: blue;
  border-radius: 1rem;
}

.clickedCard {
  transform: rotateX(0deg);
}

.matchedCard {
  pointer-events: none;
}

.new-game {
  text-align: center;
  border: 3px solid black;
  padding: 3rem;
  border-radius: 1rem;
}

.new-game h1 {
  font-size: 5rem;
}

.new-game button {
  padding: 1rem;
  background-color: black;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  color: orangered;
  margin-top: 3rem;
}

.new-game button:hover {
  background-color: orangered;
  border: 1px solid black;
  color: black;
}